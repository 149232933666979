<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getAuth, signInWithPopup, GoogleAuthProvider, GithubAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import firebaseError from "../../@utils/firebase-error";
import useJwt from '@/auth/jwt/useJwt'

export default {
	components: {
		// BSV
		BButton,
		BForm,
		BFormInput,
		BFormGroup,
		BCard,
		BCardTitle,
		BLink,
		VuexyLogo,
		BCardText,
		BInputGroup,
		BInputGroupAppend,
		BFormCheckbox,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			userEmail: '',
			password: '',
			// validation rules
			required,
			email,
		}
	},
	methods: {
		validationForm() {
			var ctx = this;

			var auth = getAuth();

			this.$refs.loginForm.validate().then(success => {
				if (success) {
					signInWithEmailAndPassword(auth, ctx.userEmail, ctx.password)
						.then(() => {
							ctx.$router.push("/");
						})
						.catch((error) => {
							const errorCode = error.code;
							const errorMessage = error.message;

							ctx.$toast({
								component: ToastificationContent,
								props: {
									title: "Error",
									text: firebaseError(errorCode),
									icon: 'UserXIcon',
									variant: 'danger',
								},
							})
						});
				}
			})
		},
		registerOauth(service) {
			var ctx = this;
			var auth = getAuth();

			if (service == "google") {
				var provider = new GoogleAuthProvider();
				signInWithPopup(auth, provider)
					.then(() => {
						ctx.$router.push("/");

					}).catch((error) => {
						const errorCode = error.code;
						ctx.$toast({
							component: ToastificationContent,
							props: {
								title: "Error",
								text: firebaseError(errorCode),
								icon: 'UserXIcon',
								variant: 'danger',
							},
						})
					});
			}
			else if (service == "github") {
				var provider = new GithubAuthProvider();
				signInWithPopup(auth, provider)
					.then(() => {
						ctx.$router.push("/");
					}).catch((error) => {
						const errorCode = error.code;

						ctx.$toast({
							component: ToastificationContent,
							props: {
								title: "Error",
								text: firebaseError(errorCode),
								icon: 'UserXIcon',
								variant: 'danger',
							},
						})
					});
			}
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">

			<!-- Login v1 -->
			<b-card class="mb-0">
				<b-link class="brand-logo">
					<vuexy-logo />

					<h2 class="brand-text text-primary ml-1">
						Enigma App
					</h2>
				</b-link>

				<b-card-title class="mb-1">
					Welcome to Enigma! 👋
				</b-card-title>
				<b-card-text class="mb-2">
					Please sign-in to your account and start the adventure
				</b-card-text>

				<!-- form -->
				<validation-observer ref="loginForm" #default="{ invalid }">
					<b-form class="auth-login-form mt-2" @submit.prevent="validationForm">

						<!-- email -->
						<b-form-group label-for="email" label="Email">
							<validation-provider #default="{ errors }" name="Email" rules="required|email">
								<b-form-input id="email" v-model="userEmail" name="login-email"
									:state="errors.length > 0 ? false : null" placeholder="john@example.com"
									autofocus />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- password -->
						<b-form-group>
							<div class="d-flex justify-content-between">
								<label for="password">Password</label>
								<b-link :to="{ name: 'auth-forgot-password' }">
									<small>Forgot Password?</small>
								</b-link>
							</div>
							<validation-provider #default="{ errors }" name="Password" rules="required">
								<b-input-group class="input-group-merge"
									:class="errors.length > 0 ? 'is-invalid' : null">
									<b-form-input id="password" v-model="password" :type="passwordFieldType"
										class="form-control-merge" :state="errors.length > 0 ? false : null"
										name="login-password" placeholder="Password" />

									<b-input-group-append is-text>
										<feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
											@click="togglePasswordVisibility" />
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- submit button -->
						<b-button variant="primary" type="submit" block :disabled="invalid">
							Sign in
						</b-button>
					</b-form>
				</validation-observer>

				<b-card-text class="text-center mt-2">
					<span>New on our platform? </span>
					<b-link :to="{ name: 'register' }">
						<span>Create an account</span>
					</b-link>
				</b-card-text>

				<div class="divider my-2">
					<div class="divider-text">
						or
					</div>
				</div>

				<!-- social button -->
				<div class="auth-footer-btn d-flex justify-content-center">
					<b-button @click="registerOauth('google')" variant="google" href="javascript:void(0)">
						<i class="fa-brands fa-google"></i>
					</b-button>

					<b-button @click="registerOauth('github')" variant="github" href="javascript:void(0)">
						<i class="fa-brands fa-github"></i>
					</b-button>
				</div>
			</b-card>
			<!-- /Login v1 -->
		</div>
	</div>
</template>